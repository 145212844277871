$(document).ready(function(){

  // $('#fadeSlider').slick({
  //   dots: false,
  //   arrow: false,
  //   infinite: true,
  //   speed: 500,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   fade: true,
  //   cssEase: 'linear'
  // });

  $('#sale').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnFocus: false,
    infinite: true,
    arrows: false,
    speed: 800,
    fade: true,
    cssEase: 'linear'
  });




  // initialize magnific popup
  $('.benefits_item_link').magnificPopup({
    type:'inline',
    midClick: true,
    removalDelay: 300,
    mainClass: 'mfp-fade' 
  });



  // batton read more 

  $('.read-more').click(function(){
    setTimeout( function() {
      $('.hidden').each(

        function(index) {
          $(this).delay(100*index).slideDown(200);
        }
      )
    }, 200);

    $(this).fadeOut();
    
  })

});